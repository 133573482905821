import { render, staticRenderFns } from "./vipwxDetail.vue?vue&type=template&id=39ac6252&scoped=true"
import script from "./vipwxDetail.vue?vue&type=script&lang=js"
export * from "./vipwxDetail.vue?vue&type=script&lang=js"
import style0 from "./vipwxDetail.vue?vue&type=style&index=0&id=39ac6252&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ac6252",
  null
  
)

component.options.__file = "vipwxDetail.vue"
export default component.exports